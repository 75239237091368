<template>
    <div class="cityA1">
      <el-container>
         <el-header style="padding: 0" height="100%">
           <vheader class="hidden-sm-and-down"></vheader>
           <ivheadera class="hidden-md-and-up"></ivheadera>
           <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>ABOUT SHENZHEN</p>
               <span>城市概览</span>
             </div>
           </div>
         </el-header>
         <el-main style="margin-top: 0" class="asaa">
           <div class="w asaa-mia">
             <div class="ec-top-foots">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item ><router-link to="/">首页</router-link></el-breadcrumb-item>
                  <el-breadcrumb-item>城市概览</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
             <el-row>
               <el-col :span="8">
                  <a name="gailan"></a>
                 <div class="header-title">
                   <img width="100%" src="../../assets/images/A/A1-h-title.png" alt="">
                 </div>
               </el-col>
             </el-row>
             <el-row>
               <div class="survey-content">
                 <el-col :xs="12" :sm="12" :md="6" v-for="(item,index) in overView" :key="index">
                   <div class="content-box"  @click="moreBtn(index)">
                     <div class="icon-box" >
                       <div class="icon-img" :style="{background:'url('+item.IMAGE+') no-repeat'}">
<!--                         <img :src="item.IMAGE" alt="">-->
                       </div>
                       <div class="icon-cn-txt">{{item.TITLE}}</div>
                       <div class="icon-en-txt" v-html="item.TITLE_EN">
                       </div>
                     </div>
                     <transition name="txt-fade">
                       <div class="txt-box" v-show="txtBox==index">
                         {{item.SHORTCONTENT}}
                       </div>
                     </transition>
                     <div class="more-box" v-bind:style="{ marginTop: txtBox==index?'0.5rem':'1rem' }">
                       <transition name="more-fade">
                         <div class="more-txt hidden-md-and-down" v-show="txtBox!=index">
                           <div class="more-cn-txt">展开详情</div>
                           <div class="more-en-txt">more</div>
                         </div>
                       </transition>
                       <i class="el-icon-arrow-down" :class="txtBox==index? 'is-active' : '' "></i>
                     </div>
                   </div>
                 </el-col>
               </div>
             </el-row>
           </div>
         </el-main>
         <div class="reform-box">
          <el-main>
            <div class="w">
              <el-row>
                <el-col :span="18">
                <a name="gaige"></a>
                  <div class="header-title">
                    <img width="100%" src="../../assets/images/A/A1-h-title2.png" alt="">
                  </div>
                </el-col>
                <el-col>
                  <div class="reform-content">
                    <!-- <video width="100%" autoplay muted loop :src="reFormSrc" poster="../../assets/images/common/loading.gif"></video> -->
                    <video class="hidden-sm-and-down" width="100%" autoplay muted loop :src="reFormSrc" poster="../../assets/images/cityplus.jpg"></video>
                    <video class="hidden-md-and-up" width="100%" controls preload muted loop :src="reFormSrc" poster="../../assets/images/cityplus.jpg"></video>
                    <!-- <video width="100%" autoplay muted loop >
                      <source :src="reFormSrc" poster="../../assets/images/common/loading.gif" type="video/webm">
                    </video> -->
                    <!-- <iframe width="100%" frameborder="0" :src="reFormSrc" allowfullscreen ></iframe> -->
                    <div class="reform-txt" v-html="reFormA">
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <div class="reform-card">
                  <el-col :xs="24" :sm="24" :md="12" v-for="(item,index) in reForm" :key="index">
                    <div class="card-box">
                      <div class="title-box">
                        <div class="card-num">{{index > 9?index:'0' + (index+1)}}</div>
                        <div class="card-title">
                          <div>
                            <div class="title-en" v-html="item.ONE">
                            </div>
                            <div class="title-cn">{{item.TITLE}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-txt">
                        {{item.TWO}}
                      </div>
                    </div>
                  </el-col>
                </div>
              </el-row>
                <div class="sin">
                  <el-popover
                  placement="right"
                  title="城市概览"
                  width="150"
                  height='20'
                  trigger="hover">
                  <a href="#gailan" slot="reference"></a>
                  </el-popover>
                  <el-popover
                    placement="left"
                    title="改革开放"
                    width="150"
                    trigger="hover">
                    <a slot="reference" href="#gaige"></a>
                  </el-popover>
                </div>
              </div>
              </el-main>
            </div>
          <gotop></gotop>
         <el-footer>
           <vfooter class="hidden-sm-and-down"></vfooter>
           <ivfooter class="hidden-md-and-up"></ivfooter>
         </el-footer>
      </el-container>
    </div>
</template>

<script src="path/to/vconsole.min.js">
</script>
<script>
 var vConsole = new VConsole();
</script>
<script>
import Gotop from '../../components/gotop'
import { szIntroduceA1, szIntroduceA12, szIntroduceA13 } from '../../api'
export default {
  data () {
    return {
      txtBox: -1,
      overView: {},
      reForm: {},
      reFormA: {},
      reFormSrc: {},
      arr: [
        {
          src: './',
          title: '发展要求',
          enTitle: 'Development Orientation',
          txt: '全市面积1997.47平方公里,境内流域面积大于1平方公里的河流共有310条,分属9大流域。深圳海洋水域总面积1145平方公里。深圳辽阔海域连接南海及太平洋,海岸线总长261公里,拥有大梅沙、小梅沙、西冲、桔钓沙等知名沙滩,大鹏半岛国家地质公园、深圳湾红树梧桐山郊野公园、内伶仃岛等自然生态保护区。'
        },
        {
          src: './',
          title: '发展要求1',
          enTitle: 'Development Orientation',
          txt: '全市面积1997.47平方公里,境内流域面积大于1平方公里的河流共有310条,分属9大流域。深圳海洋水域总面积1145平方公里。深圳辽阔海域连接南海及太平洋,海岸线总长261公里,拥有大梅沙、小梅沙、西冲、桔钓沙等知名沙滩,大鹏半岛国家地质公园、深圳湾红树梧桐山郊野公园、内伶仃岛等自然生态保护区。'
        },
        {
          src: './',
          title: '发展要求2',
          enTitle: 'Development Orientation',
          txt: '全市面积1997.47平方公里,境内流域面积大于1平方公里的河流共有310条,分属9大流域。深圳海洋水域总面积1145平方公里。深圳辽阔海域连接南海及太平洋,海岸线总长261公里,拥有大梅沙、小梅沙、西冲、桔钓沙等知名沙滩,大鹏半岛国家地质公园、深圳湾红树梧桐山郊野公园、内伶仃岛等自然生态保护区。'
        },
        {
          src: './',
          title: '发展要求3',
          enTitle: 'Development Orientation',
          txt: '全市面积1997.47平方公里,境内流域面积大于1平方公里的河流共有310条,分属9大流域。深圳海洋水域总面积1145平方公里。深圳辽阔海域连接南海及太平洋,海岸线总长261公里,拥有大梅沙、小梅沙、西冲、桔钓沙等知名沙滩,大鹏半岛国家地质公园、深圳湾红树梧桐山郊野公园、内伶仃岛等自然生态保护区。'
        },
        {
          src: './',
          title: '发展要求3',
          enTitle: 'Development Orientation',
          txt: '全市面积1997.47平方公里,境内流域面积大于1平方公里的河流共有310条,分属9大流域。深圳海洋水域总面积1145平方公里。深圳辽阔海域连接南海及太平洋,海岸线总长261公里,拥有大梅沙、小梅沙、西冲、桔钓沙等知名沙滩,大鹏半岛国家地质公园、深圳湾红树梧桐山郊野公园、内伶仃岛等自然生态保护区。'
        },
        {
          src: './',
          title: '发展要求3',
          enTitle: 'Development Orientation',
          txt: '全市面积1997.47平方公里,境内流域面积大于1平方公里的河流共有310条,分属9大流域。深圳海洋水域总面积1145平方公里。深圳辽阔海域连接南海及太平洋,海岸线总长261公里,拥有大梅沙、小梅沙、西冲、桔钓沙等知名沙滩,大鹏半岛国家地质公园、深圳湾红树梧桐山郊野公园、内伶仃岛等自然生态保护区。'
        }
      ]

    }
  },
  components: {
    Gotop
  },
  methods: {
    moreBtn (e) {
      if (this.txtBox === -1) {
        this.txtBox = e
      } else {
        this.txtBox = -1
      }
    }
  },
  mounted () {
    szIntroduceA1().then(res => {
      this.overView = res.data
      // console.log(this.overView)
    })
    szIntroduceA13().then(res => {
      this.reForm = res.data
      // console.log(this.reForm)
    })
    szIntroduceA12().then(res => {
      // console.log(res)
      this.reFormA = res.data[0].ONE
      // console.log(this.reFormA)
      this.reFormSrc = res.data[0].TWO
      // console.log(this.reFormSrc)
      var a = this.reFormSrc.split('</p>')
      // eslint-disable-next-line no-array-constructor
      var b = new Array()
      for (var i = 0; i < a.length; i++) {
        if (a[i].indexOf('src') !== -1) {
          b.push(a[i].match(/src="(\S*)"/)[1])
        }
      }
      this.reFormSrc = b
    })
  }
}
</script>
<style scpoed src='../../assets/css/CityA1.css' ></style>
<style >
.el-main {
  overflow: initial;
}
.el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
}
.asaa-mia {
  position: relative;
}
.asaa .asaa-mia .ec-top-foots {
    position: absolute;
    top: -48px;
    left: 20px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
}
.asaa .asaa-mia .ec-top-foots .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
}
.el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
}
.el-breadcrumb__inner a:hover {
    color: #fff600;
}
@media screen and (max-width: 1057px) {
  .asaa .asaa-mia .ec-top-foots {
    display: none;
  }
}
</style>
